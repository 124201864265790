<template>
<div class="space-y-4">
  <docs-layout>
    <div class="text-left">
      <p><pre class="contents">asom-container</pre> is just a wrapper for the class <pre class="contents">container</pre> of TailwindCSS, and add additional styling rules to make it behave similarly to Bootstrap container</p>
      <br/>
      <p>Below is an example of a container and a fluid container</p>
      <br/>
    </div>
  </docs-layout>
  <asom-container class="bg-blue-100 h-40 flex justify-center items-center">
    <p>Container</p>
  </asom-container>
  <asom-container fluid class="bg-blue-100 h-40 flex justify-center items-center">
    <p>Fluid container</p>
  </asom-container>
</div>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  }
}
</script>